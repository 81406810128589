<template>
  <div>
    <div class="hero-container mb-5">
      <b-container class="d-flex">
        <div class="w-100">
          <h1 class="mb-0 mt-sm-5 mb-3">
            <span>{{ $t("Pridajte sa k nám") }}</span> <br />
            <span>{{ $t("v misii pripraviť mladých") }}</span> <br />
            <span class="w-color-primary z-fancy-underline">
              {{ $t("for-life") }}
              <svgFancyUnderline />
            </span>
          </h1>
          <b-button
            variant="primary"
            class="a-poppins-btn -wider mt-3"
            href="https://zmudri.darujme.sk/podpora-zmudri-sk-17ea0/"
            target="_blank"
            rel="noopener noreferrer"
            >{{ $t("Podporte nás") }}</b-button
          >
        </div>
      </b-container>
    </div>
    <AboutNasPribeh />
    <AboutCoSmeDosiahli />
    <AboutOcenenia />
    <AboutAkoPomoct />
    <AboutNapisaliONasSection />
  </div>
</template>

<script>
import AboutAkoPomoct from "/components/About/AboutAkoPomoct";
import AboutCoSmeDosiahli from "/components/About/AboutCoSmeDosiahli";
import AboutNapisaliONasSection from "/components/About/AboutNapisaliONasSection.vue";
import AboutNasPribeh from "/components/About/AboutNasPribeh";
import AboutOcenenia from "/components/About/AboutOcenenia";

export default {
  components: {
    AboutOcenenia,
    AboutAkoPomoct,
    AboutNapisaliONasSection,
    AboutCoSmeDosiahli,
    AboutNasPribeh,
    svgFancyUnderline: () => import("/assets/icons/fancyUnderline.svg?inline"),
  },
};
</script>

<style lang="scss" scoped>
.hero-container {
  background-image: url("/headImg.webp");
}
li {
  list-style: none;
}

@media only screen and (max-width: 991px) {
  .hero-container {
    background-size: cover;
  }
}
</style>
