<template>
  <b-container>
    <b-row>
      <div class="centered">
        <h1 class="mb-5">
          <span>{{ $t("Ako nám") + " " }}</span>
          <span class="w-color-primary">{{ $t("môžete") }}</span>
          <span class="w-color-primary z-fancy-underline">
            {{ $t("pomôcť") }}
            <svgFancyUnderline />
          </span>
        </h1>
      </div>
      <b-col sm="12">
        <AboutPolozkaAkoPomoct
          :headlineText="$t('Podporte nás')"
          headlineColor="color:#0165ff"
          :imageUrl="require('/assets/icons/podporte.svg')"
          :imageAltText="$t('Podporte nás')"
        >
          {{
            $t(
              "Financie použijeme na rozšírenie našich video kurzov k ešte väčšiemu množstvu mladých ľudí v regiónoch."
            )
          }}
          {{ $t("Podporte nás") }}
          <a
            class="link"
            href="https://zmudri.teachable.com/p/2-percenta"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $t("poukázaním 2% dane") }} </a
          >,
          <a
            class="link"
            href="https://zmudri.darujme.sk/podpora-zmudri-sk-17ea0/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $t("príspevkom cez Darujme.sk") }} </a
          >, {{ $t("alebo prevodom na účet") }}
          <strong>SK40 8330 0000 0029 0155 6881</strong>.
          {{ $t("Poteší nás ľubovoľný príspevok, ktorý nám pošlete.") }}
        </AboutPolozkaAkoPomoct>

        <AboutPolozkaAkoPomoct
          :headlineText="$t('Povedzte o nás učiteľom vo vašom okolí')"
          headlineColor="color:#F2994A"
          :imageUrl="require('/assets/icons/povedzte.svg')"
          :imageAltText="$t('Povedzte o nás učiteľom vo vašom okolí')"
        >
          {{
            $t(
              "Naše bezplatné video kurzy pomohli už stovkám učiteľov po celom Slovensku. Ak máte nejakých vo svojom okolí, dajte im o nás vedieť."
            )
          }}
        </AboutPolozkaAkoPomoct>

        <AboutPolozkaAkoPomoct
          :headlineText="$t('Pridajte sa k nám')"
          headlineColor="color:#FB2564"
          :imageUrl="require('/assets/icons/pridajtesa.svg')"
          :imageAltText="$t('Pridajte sa k nám')"
        >
          {{
            $t(
              "Ste zapálený pre zábavné vzdelávanie? Vždy hľadáme ľudí, s ktorými môžeme spáchať niečo prospešné. Napíšte nám na náš e-mail:"
            )
          }}
          <a class="link" href="mailto:team@zmudri.sk"> team@zmudri.sk </a>
        </AboutPolozkaAkoPomoct>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  components: {
    svgFancyUnderline: () => import("/assets/icons/fancyUnderline.svg?inline"),
    AboutPolozkaAkoPomoct: () =>
      import("/components/About/AboutPolozkaAkoPomoct.vue"),
  },
};
</script>

<style lang="scss" scoped>
.centered {
  z-index: 1000;
  display: flex;
  width: 100%;
  justify-content: center;
}
.wrap {
  display: flex;
  width: 70%;
  justify-content: center;
  margin: 0 auto;
}
.box {
  display: flex;
  height: 15rem;
  flex-direction: row;
  gap: 5rem;
  align-items: center;
  img {
    width: 20%;
    place-self: center;
  }
  .boxContent {
    display: flex;
    width: 70%;
    flex-direction: column;
    gap: 1rem;
  }
  p {
    font-size: 14px;
    width: 80%;
    line-height: 28px;
    font-weight: 100;
  }
}
@media only screen and (min-width: 601px) and (max-width: 991px) {
  .wrap {
    min-height: 18rem;
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .wrap {
    width: 100%;
    text-align: center;
    p {
      margin: 0 auto;
    }
  }
  .centered {
    img {
      max-width: 100%;
    }
  }
  .box {
    flex-direction: column;
    gap: 2rem;
    height: auto;
    img {
      position: relative;
      bottom: -3rem;
    }
  }
}

strong {
  font-weight: 700;
}

a.link {
  font-size: inherit;
  color: #0165ff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>
